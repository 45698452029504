.navbar {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 116, 177);
    z-index: 1000;
  }
  
  .navbar ul {
    display: flex;
    justify-content: center;
    list-style-type: none;
    padding: 0;
  }
  
  .navbar li {
    margin: 0 1rem;
  }
  
  .navbar a {
    color: white;
    text-decoration: none;
    font-weight: bold;
    padding: 0.5rem 1rem;
    transition: background-color 0.3s;
  }
  
  .navbar a:hover {
    background-color: rgba(255, 180, 213, 0.8);
  }