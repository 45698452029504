@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;600&display=swap');

.home-about {
  scroll-snap-type: y mandatory;
  overflow-y: scroll;
  height: 100vh;
  font-family: 'Poppins', sans-serif;
}

.section {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.section.active {
  opacity: 1;
  transform: translateY(0);
}

.content {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: stretch;
}

.greeting { background-color: #ffb4d5; }
.about { background-color: #ffeb76; }
.ambitions { background-color: #a6eaff; }
.achievements { background-color: #65dbff; }

.image-container {
  flex: 1;
  overflow: hidden;
  position: relative;
}

.image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.text {
  flex: 2;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.1);
}

.carousel {
  width: 100%;
  height: 100%;
  position: relative;
}

.carousel img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.carousel img.active {
  opacity: 1;
}

h1 {
  font-size: 3.5rem;
  font-weight: 600;
  color: #ff74b1;
  margin-bottom: 1rem;
  line-height: 1.2;
}

h2 {
  font-size: 2.5rem;
  font-weight: 600;
  color: #ff74b1;
  margin-bottom: 1rem;
  line-height: 1.2;
}

p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #333;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 0.5rem;
  color: #333;
  position: relative;
  padding-left: 1.5rem;
}

li::before {
  content: '•';
  color: #ff74b1;
  font-size: 1.5rem;
  position: absolute;
  left: 0;
  top: -0.2rem;
}

/* Alternating layout */
.section:nth-child(even) .content {
  flex-direction: row-reverse;
}

.section:nth-child(even) .text {
  text-align: right;
}

.section:nth-child(even) li {
  padding-left: 0;
  padding-right: 1.5rem;
}

.section:nth-child(even) li::before {
  left: auto;
  right: 0;
}

/* Responsive design */
@media (max-width: 768px) {
  .content {
    flex-direction: column !important;
  }

  .image-container, .text {
    flex: none;
  }

  .image-container {
    height: 33vh;
  }

  .text {
    height: 67vh;
    text-align: left !important;
    padding: 2rem;
  }

  h1 { font-size: 2.5rem; }
  h2 { font-size: 2rem; }
  p, li { font-size: 1rem; }

  .section:nth-child(even) li {
    padding-left: 1.5rem;
    padding-right: 0;
  }

  .section:nth-child(even) li::before {
    left: 0;
    right: auto;
  }
}